import Banner from 'src/components/Banner';
import Page from 'src/components/Page';

const AboutCommunity = () => {
  return (
    <Page>
      <Banner
        title="Community"
        image="https://www.usatoday.com/gcdn/presto/2020/01/30/USAT/c3692d95-f1c5-4eb1-9fd5-7e6696705e26-HEADER_NEW_GettyImages-636342222.jpg?width=660&height=423&fit=crop&format=pjpg&auto=webp"
      />
      <div className="container">
        <div className="py-8 text-lg flex flex-col gap-4">
          <p>Coming Soon</p>
        </div>
      </div>
    </Page>
  );
};

export default AboutCommunity;
