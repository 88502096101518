import { HTMLProps } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import clsx from 'clsx';

interface PageProps extends HTMLProps<HTMLDivElement> {}

const Page = ({ className, children, ...props }: PageProps) => {
  return (
    <div>
      <div className={clsx(className, 'min-h-screen flex flex-col')} {...props}>
        <Navbar />
        <div className="flex flex-col flex-1 pt-14">{children}</div>
      </div>
      <Footer />
    </div>
  );
};

export default Page;
