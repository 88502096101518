import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'src/components/base/ui/button';
import { Card, CardHeader, CardTitle } from 'src/components/base/ui/card';
import Footer from 'src/components/Footer';
import Navbar from 'src/components/Navbar';

const isLoggedIn = false;

const Account = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/account/login', { replace: true });
    }
  }, [navigate]);

  return (
    <div>
      <Navbar />
      <div className="container flex flex-col gap-4 mt-14 min-h-screen">
        <h2 className="text-4xl">Account</h2>
        <div className="grid sm:grid-cols-[30%_70%] gap-8">
          <div className="flex flex-col gap-8">
            <div>
              <h2 className="text-xl font-medium">John Doe</h2>
              <h3 className="text-lg font-light">johndoe@email.com</h3>
            </div>
            <div>
              <h2 className="text-xl font-medium">Primary Address</h2>
              <p className="text-lg font-light">
                No addresses currently on file.
              </p>
              <Link className="font-light" to="/account/addresses">
                Add Address (0)
              </Link>
            </div>
            <Card className="bg-neutral-800 text-white aspect-video">
              <CardHeader className="flex h-full flex-col">
                <CardTitle>Membership</CardTitle>
                <div className="flex-1">
                  <p>Basic</p>
                  <ul className="list-disc list-inside">
                    <li>Free Shipping on order over $100</li>
                    <li>Earn 1 Point for every dollar</li>
                  </ul>
                </div>
                <div>
                  <Button variant="outline" className="text-black w-full">
                    Join
                  </Button>
                </div>
              </CardHeader>
            </Card>
          </div>
          <div>
            <h2 className="text-2xl">Order History</h2>
            <div>
              <p> You haven’t placed any orders yet.</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Account;
