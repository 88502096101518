import { Button } from '../base/ui/button';
import { Input } from '../base/ui/input';
import Logo from '../Logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="bg-neutral-900 py-16 text-gray-50">
      <div className="container">
        <div className="flex flex-col justify-center items-center w-full mb-16 gap-8">
          <Logo className="text-4xl mb-8" />
          <p className="text-lg font-light">Get the latest news and updates</p>
          <div className="flex-1 w-full sm:w-1/2 flex sm:flex-row flex-col gap-2 sm:gap-0">
            <Input placeholder="Enter Your E-mail" className="w-full" />
            <Button className="bg-white text-black hover:bg-neutral-300">
              Sign Me Up
            </Button>
          </div>
          <div className="flex items-center gap-8">
            <FontAwesomeIcon icon={['fab', 'facebook']} size="2x" />
            <FontAwesomeIcon icon={['fab', 'instagram']} size="2x" />
            <FontAwesomeIcon icon={['fab', 'tiktok']} size="2x" />
            <FontAwesomeIcon icon={['fab', 'threads']} size="2x" />
          </div>
        </div>
        <div>
          <div className="flex flex-col-reverse sm:flex-row text-center sm:text-right justify-between gap-8 sm:gap-0">
            <div className="flex items-end justify-center sm:justify-normal">
              <div>© {new Date().getFullYear()}</div>
            </div>
            <div className="flex flex-col sm:flex-row gap-8">
              <div className="flex flex-col gap-2">
                <h3 className="text-2xl">About</h3>
                <Link to="/about/company">The Company</Link>
                <Link to="/about/team">The Team</Link>
                <Link to="/about/community">Community</Link>
                <Link to="/about/run-club">VISION Run Club</Link>
              </div>
              <div className="flex flex-col gap-2">
                <h3 className="text-2xl">Service</h3>
                <Link to="/contact">Contact Us</Link>
                <Link to="/about/membership">Membership</Link>
              </div>
              <div className="flex flex-col gap-2">
                <h3 className="text-2xl">Information</h3>
                <Link to="/info/privacy">Privacy Policy</Link>
                <Link to="/info/terms">Term of Service</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
