import { ComponentPropsWithoutRef } from 'react';
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardFooter,
} from '../base/ui/card';
import clsx from 'clsx';
import { Link, LinkProps } from 'react-router-dom';
import { Badge } from '../base/ui/badge';

export interface Product {
  name: string;
  description: string;
  price: number;
  image: string;
  hoverImage?: string;
  to: LinkProps['to'];
  isSoldOut: boolean;
}

interface ProductCardProps
  extends ComponentPropsWithoutRef<typeof Card>,
    Product {}

const ProductCard = ({
  name = 'Coming Soon',
  description = 'Description Coming Soon',
  price = 0,
  image = 'https://placehold.co/400x400?text=Coming Soon',
  hoverImage = 'https://placehold.co/400x400?text=Coming Soon',
  to = '/',
  isSoldOut = true,
  ...props
}: ProductCardProps) => {
  return (
    <Link to={to}>
      <Card className={clsx('cursor-pointer relative')} {...props}>
        {isSoldOut && (
          <Badge className="absolute top-4 right-4">Sold Out</Badge>
        )}
        <div style={{ aspectRatio: 4 / 5 }}>
          <img
            src={image}
            onMouseOut={(e) => (e.currentTarget.src = image)}
            onMouseOver={(e) => (e.currentTarget.src = hoverImage)}
            alt={name}
            className="w-full h-full object-cover"
          />
        </div>
        <CardHeader className="p-0 py-4">
          <CardTitle>{name}</CardTitle>
          <CardDescription>{description}</CardDescription>
        </CardHeader>
        <CardFooter className="p-0 py-0 gap-1.5">
          <span>${price} USD</span>
          <span>|</span>
          <span className="text-red-500">On Sale</span>
        </CardFooter>
      </Card>
    </Link>
  );
};

export default ProductCard;
