import { forwardRef, HTMLProps } from 'react';
import Logo from '../Logo';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CartDrawer from '../CartDrawer';
import { Link } from 'react-router-dom';

interface NavbarProps extends HTMLProps<HTMLDivElement> {}

const Navbar = forwardRef<HTMLDivElement, NavbarProps>(
  ({ className, ...props }, ref) => {
    return (
      <div
        className={clsx(className, 'z-50 fixed w-full bg-white h-14 top-0')}
        {...props}
        ref={ref}
      >
        <div className="flex flex-row container justify-between items-center h-full">
          <Logo className="text-xl" />
          <div className="flex flex-row gap-6">
            <Link to="/search">
              <FontAwesomeIcon icon="magnifying-glass" />
            </Link>
            <CartDrawer>
              <div className="cursor-pointer">
                <FontAwesomeIcon icon="shopping-cart" />
              </div>
            </CartDrawer>
            <Link to="/account">
              <FontAwesomeIcon icon="user" />
            </Link>
          </div>
        </div>
      </div>
    );
  }
);

export default Navbar;
