import AthleteCard from 'src/components/AthleteCard';
import Banner from 'src/components/Banner';
import Page from 'src/components/Page';
import { athletes } from './constants';
import MarathonImage from 'src/assets/marathon.jpg';

const Atheletes = () => {
  return (
    <Page>
      <Banner title="Athletes" image={MarathonImage} />
      <div className="container">
        <div className="py-8 grid grid-cols-1 sm:grid-cols-4 gap-4">
          {athletes.map((athlete, index) => (
            <AthleteCard key={index} {...athlete} />
          ))}
        </div>
      </div>
    </Page>
  );
};

export default Atheletes;
