import clsx from 'clsx';
import { HTMLProps } from 'react';
import { Link } from 'react-router-dom';

interface LogoProps extends HTMLProps<HTMLDivElement> {}

const Logo = ({ className, ...props }: LogoProps) => {
  return (
    <Link to="/">
      <h1 className={clsx(className, 'flex gap-1')} {...props}>
        <span className="font-semibold">VISION</span>
        <span className="font-thin italic">RUNNING</span>
      </h1>
    </Link>
  );
};

export default Logo;
