import Page from 'src/components/Page';
import ProductCard from 'src/components/ProductCard';
import ManRunningImage from 'src/assets/man-running.jpg';
import { products } from 'src/mock/products';

const Athlete = () => {
  return (
    <Page>
      <div className="flex flex-col sm:flex-row gap-4">
        <div className="flex-1 sm:sticky top-14 height-max">
          <img
            src={ManRunningImage}
            alt="Product 1"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="flex-1">
          <div className="grid grid-cols-2 gap-4 px-4 pb-4">
            {products.map((product, index) => (
              <ProductCard key={index} {...product} />
            ))}
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Athlete;
