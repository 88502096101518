import { Input } from 'src/components/base/ui/input';
import Footer from 'src/components/Footer';
import Navbar from 'src/components/Navbar';

const Search = () => {
  return (
    <div>
      <Navbar />
      <div className="container py-16 min-h-screen">
        <Input
          className="text-3xl h-20 p-6 mb-8"
          placeholder="What are you looking for?"
          autoFocus
        />
        <div>
          <div className="grid grid-cols-1 sm:grid-cols-6 gap-4">
            {Array.from({ length: 6 }).map((_, index) => (
              <img
                src="https://placehold.co/400x400?text=Product+1"
                alt="Product 1"
                className="w-full h-full object-cover"
              />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Search;
