import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ComponentPropsWithoutRef } from 'react';
import { Button } from 'src/components/base/ui/button';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from 'src/components/base/ui/sheet';

const CartDrawer = ({
  children,
  ...props
}: ComponentPropsWithoutRef<typeof Sheet>) => {
  return (
    <Sheet {...props}>
      <SheetTrigger asChild>{children}</SheetTrigger>
      <SheetContent className="flex flex-col">
        <SheetHeader>
          <SheetTitle className="gap-2">
            <FontAwesomeIcon icon={'shopping-cart'} className="mr-2" />
            Cart
          </SheetTitle>
        </SheetHeader>
        <div className="flex-1">
          <h3 className="text-2xl">Your Cart Is Empty</h3>
        </div>
        {/* <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              Name
            </Label>
            <Input id="name" value="Pedro Duarte" className="col-span-3" />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="username" className="text-right">
              Username
            </Label>
            <Input id="username" value="@peduarte" className="col-span-3" />
          </div>
        </div> */}
        <SheetFooter>
          <SheetClose asChild>
            <Button type="submit" disabled className="w-full">
              Checkout
            </Button>
          </SheetClose>
          <SheetClose asChild>
            <Button variant="outline" type="submit" disabled className="w-full">
              Close
            </Button>
          </SheetClose>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default CartDrawer;
