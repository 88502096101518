import { useParams } from 'react-router-dom';
import Banner from 'src/components/Banner';
import Navbar from 'src/components/Navbar';
import { collection } from './constants';
import PageNotFound from '../PageNotFound';
import Page from 'src/components/Page';
import ProductCard from 'src/components/ProductCard';
import { products } from 'src/mock/products';

const Collection = () => {
  const { id } = useParams();

  if (!id || !(id in collection)) {
    return <PageNotFound />;
  }

  return (
    <Page>
      <Banner
        title={collection[id ?? ''].title}
        image="https://www.usatoday.com/gcdn/presto/2020/01/30/USAT/c3692d95-f1c5-4eb1-9fd5-7e6696705e26-HEADER_NEW_GettyImages-636342222.jpg?width=660&height=423&fit=crop&format=pjpg&auto=webp"
      />
      <div className="container py-16">
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-2 sm:gap-4">
          {products.map((product, index) => (
            <ProductCard key={index} {...product} />
          ))}
        </div>
      </div>
    </Page>
  );
};

export default Collection;
