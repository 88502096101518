import { ComponentPropsWithoutRef } from 'react';
import { Card, CardTitle } from '../base/ui/card';
import clsx from 'clsx';
import { Link, LinkProps } from 'react-router-dom';

export interface Collection {
  name: string;
  description: string;
  image: string;
  to: LinkProps['to'];
}

interface CollectionCardProps
  extends ComponentPropsWithoutRef<typeof Card>,
    Collection {}

const CollectionCard = ({
  name = 'Coming Soon',
  description = 'Description Coming Soon',
  image = 'https://placehold.co/400x400?text=Coming Soon',
  to = '/',
  ...props
}: CollectionCardProps) => {
  return (
    <Link to={to}>
      <Card
        className={clsx(
          'flex cursor-pointer relative aspect-square justify-center items-center transition-transform transform hover:scale-105 hover:bg-cover duration-300'
        )}
        style={{
          background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${image}) no-repeat center center/cover`,
        }}
        {...props}
      >
        <CardTitle className="text-white">{name}</CardTitle>
      </Card>
    </Link>
  );
};

export default CollectionCard;
