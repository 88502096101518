import { HashRouter, Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import Collection from './pages/Collection';
import Product from './pages/Product';
import PageNotFound from './pages/PageNotFound';
import CartDrawer from './components/CartDrawer';
import Account from './pages/Account';
import Search from './pages/Search';
import AboutCommunity from './pages/About/Community';
import AboutCompany from './pages/About/Company';
import ContactUs from './pages/About/ContactUs';
import AboutRunClub from './pages/About/RunClub';
import AboutTeam from './pages/About/Team';
import { useEffect } from 'react';
import AccountLogin from './pages/Account/Login';
import AccountCreate from './pages/Account/Create';
import Atheletes from './pages/Athletes';
import Athelete from './pages/Athlete';
import AboutMembership from './pages/About/Membership';
import InfoPrivacy from './pages/Info/Privacy';
import InfoTerms from './pages/Info/Terms';

library.add(fab, far, fas);

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}

const App = () => {
  return (
    <HashRouter future={{ v7_startTransition: true }}>
      <ScrollToTop />
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/contact" Component={ContactUs} />

        <Route path="/account" Component={Account} />
        <Route path="/account/login" Component={AccountLogin} />
        <Route path="/account/create" Component={AccountCreate} />

        <Route path="/search" Component={Search} />

        <Route path="/about/community" Component={AboutCommunity} />
        <Route path="/about/company" Component={AboutCompany} />
        <Route path="/about/run-club" Component={AboutRunClub} />
        <Route path="/about/team" Component={AboutTeam} />
        <Route path="/about/membership" Component={AboutMembership} />

        <Route path="/collection/athletes" Component={Atheletes} />
        <Route path="/collection/athlete/:id" Component={Athelete} />

        <Route path="/info/privacy" Component={InfoPrivacy} />
        <Route path="/info/terms" Component={InfoTerms} />

        <Route path="/collection/:id" Component={Collection} />
        <Route path="/product/:id" Component={Product} />
        <Route path="*" Component={PageNotFound} />
      </Routes>
      <CartDrawer />
    </HashRouter>
  );
};

export default App;
