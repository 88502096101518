import { Product } from 'src/components/ProductCard';
import ExampleShirtImage from 'src/assets/example-shirt.png';
import ExampleShirtPersonImage from 'src/assets/example-shirt-person.png';

export const products: Product[] = [
  {
    name: 'Product 1',
    description: 'Description of product 1',
    image: ExampleShirtImage,
    hoverImage: ExampleShirtPersonImage,
    to: '/product/1',
    price: 100,
    isSoldOut: true,
  },
  {
    name: 'Product 2',
    description: 'Description of product 2',
    image: ExampleShirtImage,
    hoverImage: ExampleShirtPersonImage,
    to: '/product/2',
    price: 200,
    isSoldOut: true,
  },
  {
    name: 'Product 3',
    description: 'Description of product 3',
    image: ExampleShirtImage,
    hoverImage: ExampleShirtPersonImage,
    to: '/product/3',
    price: 300,
    isSoldOut: true,
  },
  {
    name: 'Product 4',
    description: 'Description of product 4',
    image: ExampleShirtImage,
    hoverImage: ExampleShirtPersonImage,
    to: '/product/4',
    price: 400,
    isSoldOut: true,
  },
  {
    name: 'Product 5',
    description: 'Description of product 5',
    image: ExampleShirtImage,
    hoverImage: ExampleShirtPersonImage,
    to: '/product/5',
    price: 500,
    isSoldOut: true,
  },
  {
    name: 'Product 6',
    description: 'Description of product 6',
    image: ExampleShirtImage,
    hoverImage: ExampleShirtPersonImage,
    to: '/product/6',
    price: 600,
    isSoldOut: true,
  },
  {
    name: 'Product 7',
    description: 'Description of product 7',
    image: ExampleShirtImage,
    hoverImage: ExampleShirtPersonImage,
    to: '/product/7',
    price: 700,
    isSoldOut: true,
  },
  {
    name: 'Product 8',
    description: 'Description of product 8',
    image: ExampleShirtImage,
    hoverImage: ExampleShirtPersonImage,
    to: '/product/8',
    price: 800,
    isSoldOut: true,
  },
];
