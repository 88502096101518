import { Athelete } from 'src/components/AthleteCard';
import ManRunningImage from 'src/assets/man-running.jpg';

export const athletes: Athelete[] = [
  {
    name: 'John Doe',
    to: '/collection/athlete/john-doe',
    image: ManRunningImage,
    hoverImage: ManRunningImage,
  },
  {
    name: 'Jane Doe',
    to: '/collection/athlete/jane-doe',
    image: ManRunningImage,
    hoverImage: ManRunningImage,
  },
  {
    name: 'John Smith',
    to: '/collection/athlete/john-smith',
    image: ManRunningImage,
    hoverImage: ManRunningImage,
  },
  {
    name: 'Jane Smith',
    to: '/collection/athlete/jane-smith',
    image: ManRunningImage,
    hoverImage: ManRunningImage,
  },
];
