import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'src/components/base/ui/accordion';
import { Badge } from 'src/components/base/ui/badge';
import { Button } from 'src/components/base/ui/button';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from 'src/components/base/ui/carousel';
import Footer from 'src/components/Footer';
import Navbar from 'src/components/Navbar';

const Product = () => {
  return (
    <div>
      <Navbar />
      <div className="container py-16 flex flex-col gap-16">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
          <div>
            <Carousel>
              <CarouselContent>
                <CarouselItem>
                  <img
                    src="https://placehold.co/400x400"
                    alt="Product Name"
                    className="w-full"
                  />
                </CarouselItem>
                <CarouselItem>
                  <img
                    src="https://placehold.co/400x400"
                    alt="Product Name"
                    className="w-full"
                  />
                </CarouselItem>
                <CarouselItem>
                  <img
                    src="https://placehold.co/400x400"
                    alt="Product Name"
                    className="w-full"
                  />
                </CarouselItem>
              </CarouselContent>
              <CarouselPrevious />
              <CarouselNext />
            </Carousel>
          </div>
          <div>
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-3">
                <h1 className="text-2xl">Product Name</h1>
                <p className="text-lg">Price: $100 USD</p>
                <Badge className="self-start">Sold Out</Badge>
              </div>
              <div className="flex flex-row gap-2">
                <Button size="icon" disabled>
                  XS
                </Button>
                <Button variant="outline" size="icon" disabled>
                  SM
                </Button>
                <Button variant="outline" size="icon" disabled>
                  MD
                </Button>
                <Button variant="outline" size="icon" disabled>
                  LG
                </Button>
                <Button variant="outline" size="icon" disabled>
                  XL
                </Button>
              </div>
              <div>
                <Button disabled>Add To Cart</Button>
              </div>
            </div>
            <Accordion type="single" defaultValue="item-1" className="w-full">
              <AccordionItem value="item-1">
                <AccordionTrigger>Description</AccordionTrigger>
                <AccordionContent className="flex flex-col gap-4">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Morbi id rutrum leo. Nulla massa libero, lobortis sed dictum
                    nec, sodales vel eros. Nam sit amet risus nec mauris
                    consequat iaculis. Etiam nibh sapien, consequat et posuere
                    id, egestas at nibh. Sed pharetra sit amet est sit amet
                    auctor. Fusce nec tellus ac libero imperdiet venenatis. Sed
                    vestibulum volutpat sodales. Proin id consequat odio. Donec
                    eleifend vitae justo vel feugiat. Quisque euismod volutpat
                    lectus quis dignissim. Phasellus vitae fringilla velit.
                    Nulla imperdiet congue dui, sodales posuere ligula venenatis
                    ut. Integer diam urna, molestie sit amet elit vel, finibus
                    sodales elit. Curabitur vulputate mauris eget quam finibus
                    pulvinar.
                  </p>
                  <p>
                    Curabitur et ex non diam sollicitudin commodo sit amet et
                    sem. Cras porta bibendum diam, et eleifend orci bibendum
                    vulputate. Morbi sed justo finibus, dignissim massa et,
                    pellentesque lectus. Nullam accumsan vel ligula eget
                    dignissim. Nulla mollis augue a cursus porta. Lorem ipsum
                    dolor sit amet, consectetur adipiscing elit. Ut auctor orci
                    ipsum, consectetur dictum tellus hendrerit vel. Mauris
                    dignissim pellentesque eros at dignissim. Vivamus ultricies
                    egestas eros, id dignissim lectus aliquam vitae. Sed ut ante
                    vel quam laoreet pharetra quis pretium turpis. Nam sed
                    aliquam mi. Aliquam imperdiet semper sapien, non
                    sollicitudin nibh euismod ut.
                  </p>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-2">
                <AccordionTrigger>Material</AccordionTrigger>
                <AccordionContent>
                  <ul>
                    <li>100% Cotton</li>
                    <li>Machine Wash</li>
                    <li>Imported</li>
                  </ul>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-3">
                <AccordionTrigger>Sizing</AccordionTrigger>
                <AccordionContent>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Morbi id rutrum leo. Nulla massa libero, lobortis sed dictum
                    nec, sodales vel eros. Nam sit amet risus nec mauris
                    consequat iaculis. Etiam nibh sapien, consequat et posuere
                    id, egestas at nibh. Sed pharetra sit amet est sit amet
                    auctor. Fusce nec tellus ac libero imperdiet venenatis. Sed
                    vestibulum volutpat sodales. Proin id consequat odio. Donec
                    eleifend vitae justo vel feugiat. Quisque euismod volutpat
                    lectus quis dignissim. Phasellus vitae fringilla velit.
                    Nulla imperdiet congue dui, sodales posuere ligula venenatis
                    ut. Integer diam urna, molestie sit amet elit vel, finibus
                    sodales elit. Curabitur vulputate mauris eget quam finibus
                    pulvinar.
                  </p>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
        <div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi id
            rutrum leo. Nulla massa libero, lobortis sed dictum nec, sodales vel
            eros. Nam sit amet risus nec mauris consequat iaculis. Etiam nibh
            sapien, consequat et posuere id, egestas at nibh. Sed pharetra sit
            amet est sit amet auctor. Fusce nec tellus ac libero imperdiet
            venenatis. Sed vestibulum volutpat sodales. Proin id consequat odio.
            Donec eleifend vitae justo vel feugiat. Quisque euismod volutpat
            lectus quis dignissim. Phasellus vitae fringilla velit. Nulla
            imperdiet congue dui, sodales posuere ligula venenatis ut. Integer
            diam urna, molestie sit amet elit vel, finibus sodales elit.
            Curabitur vulputate mauris eget quam finibus pulvinar.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Product;
