import { ComponentPropsWithoutRef } from 'react';
import { Card, CardHeader, CardTitle, CardDescription } from '../base/ui/card';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

export interface Athelete {
  name: string;
  about?: string;
  image: string;
  hoverImage?: string;
  to: string;
}

interface AthleteCardProps
  extends ComponentPropsWithoutRef<typeof Card>,
    Athelete {}

const AthleteCard = ({
  name,
  about,
  image = 'https://placehold.co/400x400?text=Hi',
  hoverImage = 'https://placehold.co/400x400?text=Hi',
  to = '/',
  ...props
}: AthleteCardProps) => {
  return (
    <Link to={to}>
      <Card className={clsx('cursor-pointer relative')} {...props}>
        <div style={{ aspectRatio: 4 / 5 }}>
          <img
            src={image}
            onMouseOut={(e) => (e.currentTarget.src = image)}
            onMouseOver={(e) => (e.currentTarget.src = hoverImage)}
            alt={name}
            className="w-full h-full object-cover"
          />
        </div>
        <CardHeader className="p-0 py-4 text-center">
          <CardTitle>{name}</CardTitle>
          {about && <CardDescription>{about}</CardDescription>}
        </CardHeader>
      </Card>
    </Link>
  );
};

export default AthleteCard;
