import { Collection } from 'src/components/CollectionCard';
import ManRunningImage from 'src/assets/man-running.jpg';
import WomanRunningImage from 'src/assets/woman-running.jpg';

export const collections: Collection[] = [
  {
    name: 'Men',
    description: 'Description of category 1',
    image: ManRunningImage,
    to: '/collection/men',
  },
  {
    name: 'Women',
    description: 'Description of category 2',
    image: WomanRunningImage,
    to: '/collection/women',
  },
  {
    name: 'Accessories',
    description: 'Description of category 3',
    image: ManRunningImage,
    to: '/collection/accessories',
  },
  {
    name: 'Athletes',
    description: 'Description of category 4',
    image: WomanRunningImage,
    to: '/collection/athletes',
  },
];
