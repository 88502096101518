import { Link } from 'react-router-dom';
import Banner from 'src/components/Banner';
import Footer from 'src/components/Footer';
import Navbar from 'src/components/Navbar';

const PageNotFound = () => {
  return (
    <div>
      <Navbar />
      <Banner
        className="mt-14"
        title="404"
        image="https://www.usatoday.com/gcdn/presto/2020/01/30/USAT/c3692d95-f1c5-4eb1-9fd5-7e6696705e26-HEADER_NEW_GettyImages-636342222.jpg?width=660&height=423&fit=crop&format=pjpg&auto=webp"
      />
      <div className="container py-16 text-center">
        <p>
          We could not find the page you were looking for. Please use the
          navigation or click <Link to="/">HERE</Link> to go back to the home
          page.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default PageNotFound;
