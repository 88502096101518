import { Button } from 'src/components/base/ui/button';
import { Input } from 'src/components/base/ui/input';
import Footer from 'src/components/Footer';
import Navbar from 'src/components/Navbar';

const AccountCreate = () => {
  return (
    <div>
      <Navbar />
      <div className="container flex flex-col justify-center items-center gap-16 min-h-screen">
        <div className="text-center w-full max-w-96 flex flex-col gap-4">
          <h2 className="text-2xl">Create Account</h2>
          <Input placeholder="First Name" type="text" />
          <Input placeholder="Last Name" type="text" />
          <Input placeholder="E-mail" type="email" />
          <Input placeholder="Password" type="password" />
          <Input placeholder="Confirm Password" type="password" />
          <div className="flex justify-end">
            <Button>Sign Up</Button>
          </div>
        </div>
        <div>or</div>
        <div className="text-center w-full max-w-96 flex flex-col gap-4">
          <Button variant="outline" to="/account/login">
            Login
          </Button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AccountCreate;
