export const collection: { [id: string]: { title: string } } = {
  men: {
    title: 'Men',
  },
  women: {
    title: 'Women',
  },
  accessories: {
    title: 'Accessories',
  },
  other: {
    title: 'Other',
  },
};
