import { HTMLProps } from 'react';

interface BannerProps extends HTMLProps<HTMLDivElement> {
  image?: string;
  title?: string;
  subtitle?: string;
  height?: number;
}

const Banner = ({
  image = 'https://placehold.co/400x400',
  title,
  subtitle,
  height = 350,
  ...props
}: BannerProps) => {
  return (
    <div
      className="overflow-hidden"
      style={{
        width: '100%',
        height,
        background: `url('${image}') no-repeat center center/cover`,
      }}
      {...props}
    >
      <div className="container h-full w-full flex flex-col justify-end py-4 ">
        <h2 className="text-6xl font-medium hover:blur-sm italic relative">
          {title}
        </h2>
        {subtitle && (
          <h3 className="text-lg font-light text-neutral-800 hover:blur-sm italic relative">
            {subtitle}
          </h3>
        )}
      </div>
    </div>
  );
};

export default Banner;
